/* 
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;700&display=swap');

*{
    font-family: 'Roboto Slab', serif;
} */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&family=Roboto&display=swap');

* {
    /* font-family: 'Nunito', sans-serif; */
    font-family: 'Roboto', serif;
    margin-bottom: 0 !important;
}

.home__banner {
    position: relative;
    text-align: center;
    color: white;
}

.home__banner img {
    width: 100%;
    height: 600px;
}

.home__banner__overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
}

.home__banner__overlay .txt__banner {
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    line-height: 163%;
}

.home__banner__overlay .subtxt__banner {
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    line-height: 173%;
}

.banner__dec p {
    margin-top: 5px;
    font-size: 14px;
    padding: 0px 40px;
}

.banner__dec .btn__banner {
    margin-top: 25px;
    font-size: 14px;
    padding: 10px 40px;
    border: none;
    text-transform: uppercase;
    border-radius: 5px;
}

h3 {
    font-weight: 700;
    font-size: 25px;
    text-transform: uppercase;

    color: #393E46;
}

.description {
    margin-top: 10px;
    font-size: 14px;
    padding: 0px 4px;
}
.desc__about {
    margin-top: 10px;
    font-size: 14px;
    padding: 0px 4px;
    text-align: justify;
}

.services__img {
    text-align: center;
}

.services__img img {
    width: 50px;
    height: 50px;
}

.brand h4 {
    font-weight: 600;
}

.brand__img {
    text-align: center;
}

.brand__img img {
    width: 90%;
    height: 400px
}

.brand__img2 {
    text-align: center;
}

.brand__img2 img {
    width: 100%;
    height: auto;
}

.contact__img img {
    width: 100%;
    height: 400px;
}

.contact__desc p {
    margin-top: 10px;
    font-size: 14px;
    text-align: start;
}

.contact__p p {
    margin-top: 10px;
    font-size: 14px;
}

.contact__p__img img {
    width: 50px;
    height: 50px;
}


.send__message {
    padding: 10px 48px;
    /* border: 1px solid rgba(0, 0, 0, 10%); */
    /* box-shadow: 0px 8px 12px rgb(0 0 0 / 15%); */
    /* border-radius: 12px; */
}

.form__input {
    width: 100%;
}
.submit__btn {
    padding: 8px 20px;
    color: #fff;
    background: #247D71;
    border-radius: 4px;
    border: none;
}


.product__tab .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background: #03684E !important;
}
.product__tab .nav-pills .nav-link {
    color: #03684E;
}
.product__list__tab {
    background: #03684E !important;
    padding: 8px 45px;
}
.product__carousol__list .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.product__carousol__list .slick-dots li button {
    display: none;
}
.product__carousol .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #a39a9a;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.product__carousol .slick-dots li button {
    display: none;
}


.carousol__tab span {
    color: #fff;
    text-transform: uppercase;
}


@media only screen and (max-width: 768px) {
    .home__banner img {
        width: 100%;
        height: 340px;
    }

    .home__banner__overlay {
        position: absolute;
        top: 53%;
        left: 0%;
        transform: translate(0%, -65%);
    }

    .home__banner__overlay .txt__banner {
        font-weight: 500;
        line-height: 125%;
        font-size: 18px;
    }
    .home__banner__overlay .subtxt__banner {
        font-weight: 500;
        line-height: 125%;
        font-size: 14px;
    }

    .banner__dec p {
        font-size: 14px;
        padding: 0px 20px;
    }

    .banner__dec .btn__banner {
        margin-top: 10px;
        font-size: 14px;
        padding: 5px 15px;
    }

    .brand__img img {
        width: 95%;
        height: 190px;
    }

    .brand__img2 img {
        width: 100%;
        height: auto;
    }
    .send__message {
        padding: 2px 4px;
        /* border: 1px solid rgba(0, 0, 0, 10%);
        box-shadow: 0px 8px 12px rgb(0 0 0 / 15%);
        border-radius: 8px; */
    }
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
        font-size: .9rem !important;
    }
    .form__input {
        width: 100%;
    }
}