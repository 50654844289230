.mt__top {
    margin-top: 55px;
}

.elegant__img img {
    width: 100%;
    height: auto;
}

.mt__services {
    margin-top: 60px;
}

.blog__img img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

.mt__blog {
    margin-top: 30px;
}

/* react modal GetFreeMarketing start */

.my__modal__career {
    top: 45%;
    width: 96%;
}

.modal__body {
    padding: 0.2rem 0.4rem !important;
}

.header__modal__text {
    color: #484848;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 173%;
    font-size: 20px;
    text-align: start;
    overflow: hidden;
    margin-bottom: 0rem !important;
}

.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 4px;
    width: 46%;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1050;
}

.modal__btn {
    color: #fff;
    background: #247D71;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
    text-decoration: none;
    font-weight: 600;
    padding: 5px 40px;
    border-radius: 20px;
    border: 1px solid #d0e9f5 !important;
}

/* react modal GetFreeMarketing end */

.Toastify__toast-container {
    box-sizing: border-box;
    color: #fff;
    padding: 4px;
    position: fixed;
    width: 50%;
}



@media screen and (max-width: 768px) {
    .mt__top {
        margin-top: 35px;
    }

    .blog__img img {
        width: 100%;
        height: 220px;
    }

    .mt__blog {
        margin-top: 0px;
    }
    .mt__services {
        margin-top: 0px;
    }
    .mymodal {
        position: fixed;
        top: 55%;
        left: 50%;
        transform: translate(-53%, -50%);
        border: 1px solid #ccc;
        background: #fff;
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 4px;
        width: 90%;
    }
}