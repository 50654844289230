.footer_bg{
    background: #1E1E1E;
}
.text__color {
    color: #fff;
}
.ft__header{
    text-transform: uppercase;
    line-height: 180%;
    color: #fff;
}
.ft_description{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #fff;
    line-height: 160%;
}
.footer__copyright {
    color: #fff;
    font-size: small;
}

