.sticky__top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}

.navbar-nav .nav-item a:hover {
    background: #03684E;
    border-radius: 4px;
    color: #fff !important;
}

.navbar-nav .nav-active a {
    background: #03684E !important;
    border-radius: 4px !important;
    color: #fff !important;
}

.navbar__nav .nav-link {
    display: block;
    padding: 0.3rem 1rem !important;
}

.navbar__nav .shop__btn {
    background: #03684E;
    border-radius: 4px;
    color: #fff !important;
}

.modal__p {
    font-size: 14px;
}




@media screen and (max-width: 768px) {
    /* .mt__top {
        margin-top: 30px;
    } */
}